<template>
<div class="content content-fixed" style="padding-top: 80px; height: calc(100vh - 105px); overflow-y: scroll">
    <div class="container pd-0-f">
      <div class="row no-gutters">
        <div class="col-sm-12">
          <div class="table-responsive" v-if="rdy">
            
            
            <table class="table table-sm mg-b-20">
              <tbody>
                <tr>
                  <th scope="row" class="text-right bg-gray-100 tx-color-03">Улсын дугаар</th>
                  <td class="text-left">{{pnlt.vehicle.plateNo}}</td>
                </tr>
                <tr>
                  <th scope="row" class="text-right bg-gray-100 tx-color-03">Тээврийн хэрэгсэл</th>
                  <td class="text-left">{{pnlt.vehicle.mark}}, {{pnlt.vehicle.model}}</td>
                </tr>
                <tr>
                  <th scope="row" class="text-right bg-gray-100 tx-color-03">Байршил</th>
                  <td class="text-left">{{pnlt.penalty.location}}</td>
                </tr>
                <tr>
                  <th scope="row" class="text-right bg-gray-100 tx-color-03">Огноо</th>
                  <!-- <td class="text-left">{{pnlt.penalty.pnltDate | moment('YYYY.MM.DD HH:mm:SS')}}</td> -->
                  <td class="text-left">{{pnlt.penalty.pnltDate}}</td>
                </tr>
                <tr>
                  <td colspan="2"><div class="divider-text">Зөрчил</div></td>
                </tr>
                <tr>
                  <th scope="row" class="text-right bg-gray-100 tx-color-03">Гаргасан зөрчил</th>
                  <td class="text-left">{{pnlt.penalty.tovch}}</td>
                </tr>
                <tr>
                  <th scope="row" class="text-right bg-gray-100 tx-color-03">Зөрчлийн тухай хуулийн зүйл, заалт</th>
                  <td class="text-left">{{pnlt.penalty.legal}}<br /> {{pnlt.penalty.legalZaalt}}</td>
                </tr>
                <tr>
                  <td colspan="2"><div class="divider-text">Эзэмшигчийн мэдээлэл</div></td>
                </tr>
                <tr v-if="pnlt.owner.typeCode=='irgen'">
                  <th scope="row" class="text-right bg-gray-100 tx-color-03">Овог нэр</th>
                  <td class="text-left">{{pnlt.owner.lastName}} овогтой {{pnlt.owner.firstName}}</td>
                </tr>
                <tr v-else>
                  <th scope="row" class="text-right bg-gray-100 tx-color-03">Хуулийн этгээдийн нэр</th>
                  <td class="text-left">{{pnlt.owner.firstName}}</td>
                </tr>
                <tr>
                  <th scope="row" class="text-right bg-gray-100 tx-color-03">Регистр</th>
                  <td class="text-left">{{pnlt.owner.regNo}}</td>
                </tr>
                <tr>
                  <th scope="row" class="text-right bg-gray-100 tx-color-03">Холбоо барих</th>
                  <td class="text-left">{{pnlt.owner.phone}}</td>
                </tr>
              </tbody>
            </table>

            
          </div>

          <div v-if="rdy">
            <img :src="mainImg" class="wd-100p" />
          </div>

          <!-- <button v-if="rdy" @click="pay.mode=2; pay.modal=true;" class="btn btn-outline-warning mg-t-20">Хэрэв таны торгууль биш бол энд дарна уу</button> -->
        </div>
      </div>
    </div>
  </div>
  <footer class="bd-t pd-10 bg-gray-100 pos-fixed b-0 wd-100p">
    <div class="btn-group" v-if="rdy">
      <a :href="pdfUrl" target="_blank" class="btn btn-primary btn-uppercase">татах (PDF)</a>
      <button v-if="!pnlt.penalty.isPaid" @click="showPayment()" class="btn btn-success btn-uppercase">төлөх</button>
      <button v-else disabld class="btn btn-outline-success btn-uppercase">төлөгдсөн</button>
    </div>
  </footer>

  <div v-if="pay.modal" class="modal fade show" style="display: block; padding-right: 17px;">
      <div class="modal-dialog" role="document">
        <div class="modal-content tx-14">
          <div class="modal-header">
            <h6 class="modal-title" id="exampleModalLabel">{{pay.mode==1?'Төлбөр төлөх':'Мэдээллэх'}}</h6>
            <button @click="pay.modal=false" type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body" style="overflow-y: auto; height: calc(100vh - 200px)">
            <ul v-if="pay.mode==1 && isDesktop" class="list-group mg-b-20">
              <!-- qPay_deeplink -->
              <li v-for="(p,i) in pnlt.qpay.urls" :key="i" class="list-group-item d-flex align-items-center">
                <a @click="deepAfter(p)" role="button">
                  <img :src="`${p.logo}`" class="wd-30 rounded-circle mg-r-15" alt="" />
                </a>
                <div class="text-left">
                  <a @click="deepAfter(p)" role="button">
                    <h6 class="tx-13 tx-inverse tx-semibold mg-b-0">{{p.name}}</h6>
                    <div class="d-block tx-11 text-muted">төлбөр төлөх</div>
                  </a>
                </div>
              </li>
            </ul>
            <ul v-else-if="pay.mode==1 && !isDesktop" class="list-group mg-b-20">
              <li v-for="(p,i) in pnlt.qpay.urls" :key="i" class="list-group-item d-flex align-items-center">
                <a :href="p.link">
                  <img :src="`${p.logo}`" class="wd-30 rounded-circle mg-r-15" alt="" />
                </a>
                <div class="text-left">
                  <a :href="p.link">
                    <h6 class="tx-13 tx-inverse tx-semibold mg-b-0">{{p.name}}</h6>
                    <div class="d-block tx-11 text-muted">төлбөр төлөх</div>
                  </a>
                </div>
              </li>
            </ul>
            <div v-else-if="pay.mode==2">
              <p class="mg-b-0">Тайлбар</p>
              <input v-model="pay.msg" maxlength="450" class="form-control mg-y-20" type="text" />
            </div>
            <div v-else-if="pay.mode==3">
              <div class="alert alert-info mg-b-0" role="alert">
                <h6 class="alert-heading">Төлбөр төлөх</h6>
                <p>Та доорхи QR кодыг интернет банкны апп-р уншуулж төлбөрөө хийсний дараа ТӨЛБӨР ШАЛГАХ товчыг дарж үргэлжлүүлнэ үү.</p>
              </div>
              <img :src="pay.qrImg" />
            </div>
          </div>
          <div class="modal-footer">
            <button v-if="pay.mode!=3" @click="pay.modal=false" type="button" class="btn btn-secondary tx-13" data-dismiss="modal">Болих</button>
            <button v-if="pay.mode==2" @click="sendNotMy()" type="button" class="btn btn-success tx-13" data-dismiss="modal">Илгээх</button>
            <!-- <button v-if="pay.mode==1 || pay.mode==3" @click="checkIsPaid()" type="button" class="btn btn-success tx-13" data-dismiss="modal">Төлбөр шалгах</button> -->
            <button v-if="pay.mode==1 || pay.mode==3" @click="checkIsPaidNew()" type="button" class="btn btn-success tx-13" data-dismiss="modal">Төлбөр шалгах</button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="pay.modal" class="modal-backdrop fade show"></div>
</template>
<script>
import { request } from '@/api/all';
import mainURL from "../../config/mainUrl";
import { mapActions } from "vuex";
import { getToken } from "@/libs/util";
import axios from "axios";

export default {
  data(){
    return{
      rdy: false,
      pnlt:{},
      pay:{
        modal: false,
        msg: '',
        mode: 1,
        qrImg: '',
      },
      mainImg: '',
      plateImg: '',
      pdfUrl: '',
      isDesktop: false,
    }
  },
  mounted(){
    let self= this; 
    this.onResize();
    window.addEventListener('resize', this.onResize, { passive: true })

    this.handleLoading({ isLoading: true });
    request({
      api: { method: 'GET', url: 'Penalty/Detail'},
      params:{id: this.$route.params.id},
      store: this.$store
    }).then(res => {
      this.handleLoading({ isLoading: false });
      if (res !== false) {
        //console.log('Penalty/Detail=> ', res);
        this.rdy= true;
        this.pnlt= res.data.penalty;
        // axios.get(mainURL+'/Penalty/img?access_token='+getToken('token')).then(rsimg => {
        //   self.mainImg= rsimg.data;
        //console.log(rsimg.data);
        // });
        this.mainImg = mainURL+'/Penalty/img?access_token='+getToken('token');
        this.plateImg = mainURL+'/Penalty/plateImg?access_token='+getToken('token');
        this.pdfUrl = mainURL+'/Penalty/pdf?access_token='+getToken('token');
      }else{
        this.$router.push('/'+this.$route.params.id);
      }
    });
  }, 
  beforeDestroy () {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, { passive: true })
    }
  },
  methods:{
    ...mapActions(["handleLoading"]),
    onResize () {
      this.isDesktop = window.innerWidth >= 600
    },
    sendNotMy(){
      this.handleLoading({ isLoading: true });
      request({
        api: { method: 'POST', url: 'Penalty/NotMyPenalty'},
        data:{ msg: this.pay.msg, id: this.$route.params.id },
        store: this.$store
      }).then(res => {
        this.handleLoading({ isLoading: false });
        if (res !== false) {
          this.pay.modal=false;
          //this.rdy=false;
          alert(res.msg);
          //this.$router.push('/'+this.$route.params.id);
        }else{
          this.pay.modal=false;
          //alert(res.msg);
        }
        // else{
        //   this.$router.push('/'+this.$route.params.id);
        // }
      });
    },
    deepAfter(p){
      //this.pay.qrImg= 'data:image/png;base64,'+this.pnlt.qpay.qPay_QRimage;
      this.pay.qrImg= 'data:image/png;base64,'+this.pnlt.qpay.qr_image;
      this.pay.mode= 3;
    },
    showPayment(){
      this.handleLoading({ isLoading: true });
      request({
        api: { method: 'POST', url: 'Penalty/PaymentDetail'},
        store: this.$store
      }).then(res => {
        this.handleLoading({ isLoading: false });
        if (res !== false) {
          this.pay.mode=1; 
          this.pay.modal=true;
        }
      });
    },
    checkIsPaidNew(){
      window.location.reload(true);
    },
    checkIsPaid() {
      this.handleLoading({ isLoading: true });
      request({
        api: { method: 'POST', url: 'Penalty/CheckInvoice'},
        data:{
          invoiceId: this.pnlt.qpay.invoice_id
        },
        store: this.$store
      }).then(res => {
        this.handleLoading({ isLoading: false });
        if (res !== false) {
          //console.log(res);
          if(res.result==1){
            alert('Амжилттай төлөгдлөө');
            this.$router.push('/'+this.$route.params.id);
          }
          else if(res.result==0){
            alert('Төлбөр төлөгдөөгүй байна');
          }
        }
      });
    },
  }
}
</script>

